import React, { useContext } from 'react'
import pageContextProvider from '@helpers/pageContextProvider'
import { Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import { useBlogCategories, useBlogTags } from '@helpers-blog'

const Collection = ({ data: { posts, collectionInfo } }) => {
  const context = useContext(pageContextProvider)
  const categories = useBlogCategories()
  const tags = useBlogTags()

  return (
    <>
      <Seo title={`${collectionInfo.name} Franchises`} />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={`${collectionInfo.name} Franchises`}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
          totalCount={posts.totalCount}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
              omitCategory={
                context.pageContext &&
                context.pageContext.collectionType === 'category'
              }
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...posts.pageInfo} {...collectionInfo} />
      </PreFooter>
      <Divider space={4} />
      <Stack effectProps={{ effect: false }}>
        <Categories categories={categories} variant='horizontal' omitTitle />
      </Stack>
      <Divider space={2} />
      <Stack effectProps={{ effect: false }}>
          <Tags tags={tags} omitTitle />
      </Stack>
    </>
  )
}
export default Collection
